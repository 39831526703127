/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow strict-local
 */

import React, { useEffect } from "react";
import Manager from "./Manager";
import { initializeApp } from "firebase/app";
import { getFunctions } from "firebase/functions";

const app = initializeApp({
  apiKey: "AIzaSyB1q9rs05tpDnENMQQ5-gw18OpRu1m7HWM",
  authDomain: "fightback-30512.firebaseapp.com",
  projectId: "fightback-30512",
});
const functions = getFunctions(app);

const App = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        minWidth: "500px",
      }}
    >
      <Manager functions={functions} />
    </div>
  );
};

export default App;
