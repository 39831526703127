import React, { useEffect, useState } from "react";
import Home from "./Home";
import RegisterDevice from "./RegisterDevice";
import Loading from "./Loading";
import { httpsCallable } from "firebase/functions";

const Manager = (props) => {
  const functions = props.functions;
  const [validatedClient, setValidatedClient] = useState(false);
  const [loading, setLoading] = useState(true);

  const getLicenseKey = () => {
    try {
      const value = localStorage.getItem("licenseKey");
      if (value !== null) {
        // value previously stored

        return value;
      }
    } catch (e) {
      // error reading value
    }
  };

  const handleValidation = async () => {
    return new Promise(async (res) => {
      let licenseKey = getLicenseKey();
      const deviceId = localStorage.getItem("deviceId");
      const func = httpsCallable(functions, "validateLicense");

      func({
        licenseKey: licenseKey,
        deviceId: deviceId,
        web: true,
      }).then((response) => {
        console.log(response);
        res(response.data);
      });
    });
  };

  useEffect(() => {
    const handleInitialValidationCheck = async () => {
      const validated = await handleValidation();
      setValidatedClient(validated.success);
      setLoading(false);
    };
    handleInitialValidationCheck();
  }, []);

  return loading ? (
    <Loading functions={functions} />
  ) : !validatedClient ? (
    <RegisterDevice
      functions={functions}
      setValidatedClient={setValidatedClient}
    />
  ) : (
    <Home functions={functions} />
  );
};

export default Manager;
