import React from "react";

const Loading = () => {
  return (
    <div
      style={{
        height: "720px",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        width: "480px",
        backgroundColor: "black",
        paddingTop: 40,
      }}
    >
      <div style={{ color: "white" }}>Loading...</div>
    </div>
  );
};

export default Loading;
