import React, { useState } from "react";
import { httpsCallable } from "firebase/functions";
import { v4 as uuidv4 } from "uuid";
import helpImage from "./assets/help.png";

const RegisterDevice = (props) => {
  const functions = props.functions;
  const [licenseKey, setLicenseKey] = useState("");
  const [loading, setLoading] = useState(false);

  const handleRegisterDevice = () => {
    if (licenseKey.trim().replace(/-/g, "").length !== 16) {
      alert("License key must be a 16 digit code.");
      return;
    }
    setLoading(true);
    const uniqueId = uuidv4();
    const func = httpsCallable(functions, "registerDevice");

    func({
      licenseKey: licenseKey,
      deviceId: uniqueId,
      web: true,
      deviceName: "Desktop App",
      deviceOs: navigator.userAgentData.platform,
    }).then((response) => {
      console.log(response);
      if (response.data.success) {
        alert("Device registered!");
        const storeData = async () => {
          try {
            localStorage.setItem("licenseKey", licenseKey.trim());
            localStorage.setItem("deviceId", uniqueId);
            props.setValidatedClient(true);
            setLoading(false);
          } catch (e) {
            // saving error
            setLoading(false);
            alert("Registration failed! Error: " + e);
          }
        };
        storeData();
      } else {
        setLoading(false);
        alert("Registration failed! Error: " + response.data.error);
      }
    });
  };

  return loading ? (
    <div
      style={{
        height: "720px",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        width: "480px",
        backgroundColor: "black",
        paddingTop: 40,
      }}
    >
      <div style={{ color: "white" }}>Registering</div>
    </div>
  ) : (
    <div
      style={{
        height: "720px",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        width: "480px",
        backgroundColor: "black",
        paddingTop: 20,
      }}
    >
      <div
        onClick={() =>
          window.open("http://webhelprx.com/FightBack!Help.html", "_blank")
        }
        style={{
          marginLeft: "auto",
          cursor: "pointer",
          zIndex: 1,
          marginRight: 20,
        }}
      >
        <img style={{ width: 50 }} src={helpImage} />
      </div>
      <div style={{ marginTop: "28%" }}>
        <div style={{ color: "white" }}>Register Device</div>
      </div>
      <div style={{ marginTop: 10 }}>
        <input
          placeholder="   Enter license key..."
          placeholderTextColor={"black"}
          onChange={(change) => setLicenseKey(change.target.value)}
          style={{
            borderWidth: 1,
            width: 240,
            height: 50,
            backgroundColor: "white",
          }}
        />
      </div>

      <div style={{ marginTop: 20 }}>
        <div
          onClick={handleRegisterDevice}
          style={{
            borderWidth: 1,
            width: 160,
            height: 40,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            display: "flex",
          }}
        >
          <div style={{ color: "black" }}>Register</div>
        </div>
      </div>
    </div>
  );
};

export default RegisterDevice;
