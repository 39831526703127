import React, { useEffect, useState, useRef } from "react";
import speakerImage from "./assets/speaker.png";
import { httpsCallable } from "firebase/functions";
import {
  FaPlus,
  FaMinus,
  FaPlay,
  FaSquare,
  FaChevronDown,
  FaChevronUp,
  FaChevronRight,
  FaChevronLeft,
} from "react-icons/fa";
import helpImage from "./assets/help.png";
var CryptoJS = require("crypto-js");
// every action the user makes should be validated via the api as well as the client should be validated and display the correct component based on validation

// sort category names

// make desktop version a little smaller.

const Home = (props) => {
  const functions = props.functions;
  const options = require("./options.json");
  const [selectedSoundName, setSelectedSoundName] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedFavorite, setSelectedFavorite] = useState(0);
  const [allCategories, setAllCategories] = useState([]);
  const [allPhrases, setAllPhrase] = useState([]);
  const [phrasesInSelectedCategory, setPhrasesInSelectedCategory] = useState(
    []
  );
  const [volume, setVolume] = useState(1);
  const [favorites, setFavorites] = useState([]);
  const [favoriteMode, setFavoriteMode] = useState(false);
  const [reload, setReload] = useState(false);
  const currentAudio = useRef();

  useEffect(() => {
    let cats = [];
    let phrases = [];
    handleValidation().then((res) => {
      if (res.success) {
        for (let index = 0; index < options.length; index++) {
          const option = options[index];
          phrases.push({ name: option.name, categories: option.categories });
          for (let i = 0; i < option.categories.length; i++) {
            const category = option.categories[i];
            if (!cats.includes(category)) {
              cats.push(category);
            }
          }
        }

        setAllCategories(cats.sort());
        setAllPhrase(phrases);

        const indexOfSoundInNewCategory = options.findIndex((a) =>
          a.categories.includes(cats[selectedCategory])
        );

        if (indexOfSoundInNewCategory !== -1) {
          let phrasesInCat = [];
          for (let index = 0; index < phrases.length; index++) {
            let phrase = phrases[index];
            const indexOf = phrase.categories.findIndex(
              (a) => a === cats[selectedCategory]
            );
            if (indexOf !== -1) {
              phrasesInCat.push(phrase);
            }
          }
          setFavoriteMode(false);
          setSelectedSoundName(0);
          setPhrasesInSelectedCategory(phrasesInCat);
        }

        const getData = async () => {
          try {
            const value = localStorage.getItem("favorites");
            if (value !== null) {
              // value previously stored
              const jsonObject = JSON.parse(value);
              setFavorites(jsonObject);
            } else {
              const storeData = async () => {
                try {
                  const jsonValue = JSON.stringify([]);
                  localStorage.setItem("favorites", jsonValue);
                } catch (e) {
                  // saving error
                }
              };
              storeData();
            }
          } catch (e) {
            // error reading value
          }
        };
        getData();
      }
    });
  }, []);

  useEffect(() => {
    handleValidation().then((res) => {
      if (res.success) {
        const indexOfSoundInNewCategory = options.findIndex((a) =>
          a.categories.includes(allCategories[selectedCategory])
        );

        if (indexOfSoundInNewCategory !== -1) {
          let phrasesInCat = [];
          for (let index = 0; index < allPhrases.length; index++) {
            let phrase = allPhrases[index];
            const indexOf = phrase.categories.findIndex(
              (a) => a === allCategories[selectedCategory]
            );
            if (indexOf !== -1) {
              phrasesInCat.push(phrase);
            }
          }
          setFavoriteMode(false);
          setSelectedSoundName(0);
          setPhrasesInSelectedCategory(phrasesInCat);
        }
      }
    });
  }, [selectedCategory]);

  const getAudioFile = () => {
    let file;

    switch (
      favoriteMode
        ? favorites[selectedFavorite].name
        : phrasesInSelectedCategory[selectedSoundName].name
    ) {
      case "001 Aoogah":
        file = require("./encryptedAudios/001 Aoogah.json");
        break;
      case "002 Aoogah 2":
        file = require("./encryptedAudios/002 Aoogah 2.json");
        break;
      case "003 Phone ring":
        file = require("./encryptedAudios/003 Phone ring.json");
        break;
      case "004 Shut off":
        file = require("./encryptedAudios/004 Shut off.json");
        break;
      case "005 Snore 1":
        file = require("./encryptedAudios/005 Snore 1.json");
        break;
      case "006 Snore 2":
        file = require("./encryptedAudios/006 Snore 2.json");
        break;
      case "007 Sonar":
        file = require("./encryptedAudios/007 Sonar.json");
        break;
      case "008 Type writer":
        file = require("./encryptedAudios/008 Type writer.json");
        break;
      case "009 Warning horn":
        file = require("./encryptedAudios/009 Warning horn.json");
        break;
      case "010 Whah whah":
        file = require("./encryptedAudios/010 Whah whah.json");
        break;
      case "011 Woow":
        file = require("./encryptedAudios/011 Woow.json");
        break;
      case "012 Yay":
        file = require("./encryptedAudios/012 Yay.json");
        break;
      case "013 Small prop plane":
        file = require("./encryptedAudios/013 Small prop plane.json");
        break;
      case "014  Piezo alarm":
        file = require("./encryptedAudios/014  Piezo alarm.json");
        break;
      case "015 Smoke detector":
        file = require("./encryptedAudios/015 Smoke detector.json");
        break;
      case "016 Henhouse":
        file = require("./encryptedAudios/016 Henhouse.json");
        break;
      case "017 Frogs":
        file = require("./encryptedAudios/017 Frogs.json");
        break;
      case "018 Bleating goat":
        file = require("./encryptedAudios/018 Bleating goat.json");
        break;
      case "019 Rooster":
        file = require("./encryptedAudios/019 Rooster.json");
        break;
      case "020 Donkey braying":
        file = require("./encryptedAudios/020 Donkey braying.json");
        break;
      case "021 Horse neighbors":
        file = require("./encryptedAudios/021 Horse neighbors.json");
        break;
      case "022 Horse Neighing":
        file = require("./encryptedAudios/022 Horse Neighing.json");
        break;
      case "023 Censor beep":
        file = require("./encryptedAudios/023 Censor beep.json");
        break;
      case "024 Cardiac arrest EKG":
        file = require("./encryptedAudios/024 Cardiac arrest EKG.json");
        break;
      case "025 Backup alarm":
        file = require("./encryptedAudios/025 Backup alarm.json");
        break;
      case "026 Church bells":
        file = require("./encryptedAudios/026 Church bells.json");
        break;
      case "027 Ship horn":
        file = require("./encryptedAudios/027 Ship horn.json");
        break;
      case "028 Timer tick":
        file = require("./encryptedAudios/028 Timer tick.json");
        break;
      case "029 Floppy disk reading":
        file = require("./encryptedAudios/029 Floppy disk reading.json");
        break;
      case "030 Computer keyboard":
        file = require("./encryptedAudios/030 Computer keyboard.json");
        break;
      case "031 Keyboard slow":
        file = require("./encryptedAudios/031 Keyboard slow.json");
        break;
      case "032 Cell interference":
        file = require("./encryptedAudios/032 Cell interference.json");
        break;
      case "033 Cell interference 2":
        file = require("./encryptedAudios/033 Cell interference 2.json");
        break;
      case "034 Radio frequency sweep":
        file = require("./encryptedAudios/034 Radio frequency sweep.json");
        break;
      case "035 Internet connect":
        file = require("./encryptedAudios/035 Internet connect.json");
        break;
      case "036 Dial phone":
        file = require("./encryptedAudios/036 Dial phone.json");
        break;
      case "037 Countdown Male":
        file = require("./encryptedAudios/037 Countdown Male.json");
        break;
      case "038 Sputnik":
        file = require("./encryptedAudios/038 Sputnik.json");
        break;
      case "039 Ghost":
        file = require("./encryptedAudios/039 Ghost.json");
        break;
      case "040 Applause":
        file = require("./encryptedAudios/040 Applause.json");
        break;
      case "041 Electric hand mixer":
        file = require("./encryptedAudios/041 Electric hand mixer.json");
        break;
      case "042 Gun Shot beretta":
        file = require("./encryptedAudios/042 Gun Shot beretta.json");
        break;
      case "043 Gun Shot winchester":
        file = require("./encryptedAudios/043 Gun Shot winchester.json");
        break;
      case "044 Yawn":
        file = require("./encryptedAudios/044 Yawn.json");
        break;
      case "045 Cough Woman":
        file = require("./encryptedAudios/045 Cough Woman.json");
        break;
      case "046 Throwup":
        file = require("./encryptedAudios/046 Throwup.json");
        break;
      case "047 Fog horn":
        file = require("./encryptedAudios/047 Fog horn.json");
        break;
      case "048 Hair clippers":
        file = require("./encryptedAudios/048 Hair clippers.json");
        break;
      case "049 Sewing machine":
        file = require("./encryptedAudios/049 Sewing machine.json");
        break;
      case "050 Inkjet printer":
        file = require("./encryptedAudios/050 Inkjet printer.json");
        break;
      case "051 Mower":
        file = require("./encryptedAudios/051 Mower.json");
        break;
      case "052 Drum roll":
        file = require("./encryptedAudios/052 Drum roll.json");
        break;
      case "053 Machine language":
        file = require("./encryptedAudios/053 Machine language.json");
        break;
      case "054 Tombstone opening":
        file = require("./encryptedAudios/054 Tombstone opening.json");
        break;
      case "055 Flying saucer 1":
        file = require("./encryptedAudios/055 Flying saucer 1.json");
        break;
      case "056 Flying saucer 2":
        file = require("./encryptedAudios/056 Flying saucer 2.json");
        break;
      case "057 Hacksaw":
        file = require("./encryptedAudios/057 Hacksaw.json");
        break;
      case "058 Wood saw":
        file = require("./encryptedAudios/058 Wood saw.json");
        break;
      case "059 Chain saw":
        file = require("./encryptedAudios/059 Chain saw.json");
        break;
      case "060 Impact wrench":
        file = require("./encryptedAudios/060 Impact wrench.json");
        break;
      case "061 Power drill":
        file = require("./encryptedAudios/061 Power drill.json");
        break;
      case "062 Squeaky toy":
        file = require("./encryptedAudios/062 Squeaky toy.json");
        break;
      case "063 Train horn":
        file = require("./encryptedAudios/063 Train horn.json");
        break;
      case "064 Car blinker":
        file = require("./encryptedAudios/064 Car blinker.json");
        break;
      case "065 Ford engine":
        file = require("./encryptedAudios/065 Ford engine.json");
        break;
      case "066 Headlight reminder":
        file = require("./encryptedAudios/066 Headlight reminder.json");
        break;
      case "067 Small tractor":
        file = require("./encryptedAudios/067 Small tractor.json");
        break;
      case "068 Motorcycle":
        file = require("./encryptedAudios/068 Motorcycle.json");
        break;
      case "069 Ambulance siren":
        file = require("./encryptedAudios/069 Ambulance siren.json");
        break;
      case "070 Screeching tires":
        file = require("./encryptedAudios/070 Screeching tires.json");
        break;
      case "071 Laughter 1":
        file = require("./encryptedAudios/071 Laughter 1.json");
        break;
      case "072 Laughter 2":
        file = require("./encryptedAudios/072 Laughter 2.json");
        break;
      case "073 Countdown walkie talkie":
        file = require("./encryptedAudios/073 Countdown walkie talkie.json");
        break;
      case "074 Scream":
        file = require("./encryptedAudios/074 Scream.json");
        break;
      case "075 Water drops":
        file = require("./encryptedAudios/075 Water drops.json");
        break;
      case "076 Urinating":
        file = require("./encryptedAudios/076 Urinating.json");
        break;
      case "077 Soda Fizz":
        file = require("./encryptedAudios/077 Soda Fizz.json");
        break;
      case "078 Flush and fill":
        file = require("./encryptedAudios/078 Flush and fill.json");
        break;
      case "079 Whistling melody":
        file = require("./encryptedAudios/079 Whistling melody.json");
        break;
      case "080 Thank You for Calling the Fraud Bureau":
        file = require("./encryptedAudios/080 Thank You for Calling the Fraud Bureau.json");
        break;
      case "081 You Have Reached the Fraud Bureau":
        file = require("./encryptedAudios/081 You Have Reached the Fraud Bureau.json");
        break;
      case "082 I No Longer Have That Vehicle":
        file = require("./encryptedAudios/082 I No Longer Have That Vehicle.json");
        break;
      case "083 I No Longer Drive":
        file = require("./encryptedAudios/083 I No Longer Drive.json");
        break;
      case "084 With COVID I Dont Drive Much":
        file = require("./encryptedAudios/084 With COVID I Dont Drive Much.json");
        break;
      case "085 The Property Is Not For Sale":
        file = require("./encryptedAudios/085 The Property Is Not For Sale.json");
        break;
      case "086 Let Me Hand the Phone Over to My Friend":
        file = require("./encryptedAudios/086 Let Me Hand the Phone Over to My Friend.json");
        break;
      case "087 Funny Thing Im Meeting with My Tax Attorney Right Now":
        file = require("./encryptedAudios/087 Funny Thing Im Meeting with My Tax Attorney Right Now.json");
        break;
      case "088 You Have Reached the National Government Watchdog Commisson":
        file = require("./encryptedAudios/088 You Have Reached the National Government Watchdog Commisson.json");
        break;
      case "089 City Morgue You Stab Em We Slab Em":
        file = require("./encryptedAudios/089 City Morgue You Stab Em We Slab Em.json");
        break;
      case "090 Please Stay On the Line as we Obtain Your IP Address":
        file = require("./encryptedAudios/090 Please Stay On the Line as we Obtain Your IP Address.json");
        break;
      case "091 Third Floor Psychiatric Ward":
        file = require("./encryptedAudios/091 Third Floor Psychiatric Ward.json");
        break;
      case "092 Dewey Cheatham and Howe Law Offices":
        file = require("./encryptedAudios/092 Dewey Cheatham and Howe Law Offices.json");
        break;
      case "093 You Have Reached the State Fraud Department. What is Your Inquiry":
        file = require("./encryptedAudios/093 You Have Reached the State Fraud Department. What is Your Inquiry.json");
        break;
      case "094 Aaron and Sons Funeral Chapel":
        file = require("./encryptedAudios/094 Aaron and Sons Funeral Chapel.json");
        break;
      case "095 State Wildlife Complaint Department":
        file = require("./encryptedAudios/095 State Wildlife Complaint Department.json");
        break;
      case "096 Middlefinger Insurance Would You Like a Home Auto or Life Quote":
        file = require("./encryptedAudios/096 Middlefinger Insurance Would You Like a Home Auto or Life Quote.json");
        break;
      case "097 We Require Identification of the Caller":
        file = require("./encryptedAudios/097 We Require Identification of the Caller.json");
        break;
      case "098 Youre Caller Number 4 on our KASQ radio Program":
        file = require("./encryptedAudios/098 Youre Caller Number 4 on our KASQ radio Program.json");
        break;
      case "099 Service Department What is the Make and Model of Your Vehicle":
        file = require("./encryptedAudios/099 Service Department What is the Make and Model of Your Vehicle.json");
        break;
      case "100 Hold Music 1":
        file = require("./encryptedAudios/100 Hold Music 1.json");
        break;
      case "101 Music Box 1":
        file = require("./encryptedAudios/101 Music Box 1.json");
        break;
      case "102 Hold Music 2":
        file = require("./encryptedAudios/102 Hold Music 2.json");
        break;
      case "103 Hold Music 3":
        file = require("./encryptedAudios/103 Hold Music 3.json");
        break;
      case "104 Hold Music 4":
        file = require("./encryptedAudios/104 Hold Music 4.json");
        break;
      case "105 Alexa Having Trouble Connecting":
        file = require("./encryptedAudios/105 Alexa Having Trouble Connecting.json");
        break;
      case "106 Alexa This Device is Ready for Setup":
        file = require("./encryptedAudios/106 Alexa This Device is Ready for Setup.json");
        break;
      case "107 Alexa":
        file = require("./encryptedAudios/107 Alexa.json");
        break;
      case "108 Return to Gameshow Music":
        file = require("./encryptedAudios/108 Return to Gameshow Music.json");
        break;
      case "109 Hold Music 5":
        file = require("./encryptedAudios/109 Hold Music 5.json");
        break;
      case "110 Clean Their Ears Out 1":
        file = require("./encryptedAudios/110 Clean Their Ears Out 1.json");
        break;
      case "111 Clean Their Ears Out 2":
        file = require("./encryptedAudios/111 Clean Their Ears Out 2.json");
        break;
      case "112 Clean Their Ears Out 3":
        file = require("./encryptedAudios/112 Clean Their Ears Out 3.json");
        break;
      case "113 Hold Music 6":
        file = require("./encryptedAudios/113 Hold Music 6.json");
        break;
      case "114 DoSiDo Music":
        file = require("./encryptedAudios/114 DoSiDo Music.json");
        break;
      case "115 Music Box 2":
        file = require("./encryptedAudios/115 Music Box 2.json");
        break;
      case "116 International TV Surfing":
        file = require("./encryptedAudios/116 International TV Surfing.json");
        break;
      case "117 Bowling Alley Background Noise":
        file = require("./encryptedAudios/117 Bowling Alley Background Noise.json");
        break;
      case "118 Silence":
        file = require("./encryptedAudios/118 Silence.json");
        break;
      case "119 Fish and Wildlife service":
        file = require("./encryptedAudios/119 Fish and Wildlife service.json");
        break;
      case "120 Urban plumbing":
        file = require("./encryptedAudios/120 Urban plumbing.json");
        break;
      case "121 Dry cleaners":
        file = require("./encryptedAudios/121 Dry cleaners.json");
        break;
      case "122 Channel 30 news desk":
        file = require("./encryptedAudios/122 Channel 30 news desk.json");
        break;
      case "123 County Assessors office":
        file = require("./encryptedAudios/123 County Assessors office.json");
        break;
      case "124 Municipal airport security":
        file = require("./encryptedAudios/124 Municipal airport security.json");
        break;
      case "125 South End Marine Charter":
        file = require("./encryptedAudios/125 South End Marine Charter.json");
        break;
      case "126 Mall tuxedo rental":
        file = require("./encryptedAudios/126 Mall tuxedo rental.json");
        break;
      case "127 Flogger Airlines":
        file = require("./encryptedAudios/127 Flogger Airlines.json");
        break;
      case "128 Thank you for calling Happy Couple bridal dresses":
        file = require("./encryptedAudios/128 Thank you for calling Happy Couple bridal dresses.json");
        break;
      case "129 Criminal Investigative Department":
        file = require("./encryptedAudios/129 Criminal Investigative Department.json");
        break;
      case "130 City medical examiners office":
        file = require("./encryptedAudios/130 City medical examiners office.json");
        break;
      case "131 Thank you for calling the Post Service":
        file = require("./encryptedAudios/131 Thank you for calling the Post Service.json");
        break;
      case "132 Thank you for contacting Malphs Construction":
        file = require("./encryptedAudios/132 Thank you for contacting Malphs Construction.json");
        break;
      case "133 Limo service":
        file = require("./encryptedAudios/133 Limo service.json");
        break;
      case "134 Medical office. Press 1":
        file = require("./encryptedAudios/134 Medical office. Press 1.json");
        break;
      case "135 New Eggs Pharmacy. Press 1":
        file = require("./encryptedAudios/135 New Eggs Pharmacy. Press 1.json");
        break;
      case "136 Big Bingo Casino. Where youre a winner every day. Press 1":
        file = require("./encryptedAudios/136 Big Bingo Casino. Where youre a winner every day. Press 1.json");
        break;
      case "137 Middlefinger University. For English press 1":
        file = require("./encryptedAudios/137 Middlefinger University. For English press 1.json");
        break;
      case "138 What I have never been to South America":
        file = require("./encryptedAudios/138 What I have never been to South America.json");
        break;
      case "139 I dont need insurance Im dead":
        file = require("./encryptedAudios/139 I dont need insurance Im dead.json");
        break;
      case "140 Stutterball Lanes":
        file = require("./encryptedAudios/140 Stutterball Lanes.json");
        break;
      case "141 Service department. What is the make model and vehicle identification number":
        file = require("./encryptedAudios/141 Service department. What is the make model and vehicle identification number.json");
        break;
      case "142 Nobody I know would call at this hour!":
        file = require("./encryptedAudios/142 Nobody I know would call at this hour.json");
        break;
      case "143 Wire the money or else":
        file = require("./encryptedAudios/143 Wire the money or else.json");
        break;
      case "144 Bait Tackle and Lingerie Shop":
        file = require("./encryptedAudios/144 Bait Tackle and Lingerie Shop.json");
        break;
      case "145 Crematorium and Crime Scene Cleanup":
        file = require("./encryptedAudios/145 Crematorium and Crime Scene Cleanup.json");
        break;
      case "146 Mule barn head jackass speaking":
        file = require("./encryptedAudios/146 Mule barn head jackass speaking.json");
        break;
      case "147 Yes I would like a large extra cheese with sausage":
        file = require("./encryptedAudios/147 Yes I would like a large extra cheese with sausage.json");
        break;
      case "148 Hi is Dave there":
        file = require("./encryptedAudios/148 Hi is Dave there.json");
        break;
      case "149 Who is this How did you get this number":
        file = require("./encryptedAudios/149 Who is this How did you get this number.json");
        break;
      case "150 The individual you are looking for is under investigation":
        file = require("./encryptedAudios/150 The individual you are looking for is under investigation.json");
        break;
      case "151 My name is Dr Emmett Brown":
        file = require("./encryptedAudios/151 My name is Dr Emmett Brown.json");
        break;
      case "152 I have a Capitol 2 card":
        file = require("./encryptedAudios/152 I have a Capitol 2 card.json");
        break;
      case "153 Who are you looking for":
        file = require("./encryptedAudios/153 Who are you looking for.json");
        break;
      case "154 Thanks for calling technical support":
        file = require("./encryptedAudios/154 Thanks for calling technical support.json");
        break;
      case "155 This call is being recorded for quality and training purposes":
        file = require("./encryptedAudios/155 This call is being recorded for quality and training purposes.json");
        break;
      case "156 Telemarketer joke line":
        file = require("./encryptedAudios/156 Telemarketer joke line.json");
        break;
      case "157 Telemarketer joke line":
        file = require("./encryptedAudios/157 Telemarketer joke line.json");
        break;
      case "158 Talk V E R Y  S L O W L Y":
        file = require("./encryptedAudios/158 Talk V E R Y  S L O W L Y.json");
        break;
      case "159 I am on home incarceration":
        file = require("./encryptedAudios/159 I am on home incarceration.json");
        break;
      case "160 I just filed for bankruptcy and sure could use some money":
        file = require("./encryptedAudios/160 I just filed for bankruptcy and sure could use some money.json");
        break;
      case "161 I dont have any friends":
        file = require("./encryptedAudios/161 I dont have any friends.json");
        break;
      case "162 Judy! Is that you":
        file = require("./encryptedAudios/162 Judy Is that you.json");
        break;
      case "163 I am so glad you called because no one these days seems to care":
        file = require("./encryptedAudios/163 I am so glad you called because no one these days seems to care.json");
        break;
      case "164 Please spell your name":
        file = require("./encryptedAudios/164 Please spell your name.json");
        break;
      case "165 Do you know how to get blood out":
        file = require("./encryptedAudios/165 Do you know how to get blood out.json");
        break;
      case "166 What is your company name":
        file = require("./encryptedAudios/166 What is your company name.json");
        break;
      case "167 Telemarketer joke line":
        file = require("./encryptedAudios/167 Telemarketer joke line.json");
        break;
      case "168 Hi this is Eric":
        file = require("./encryptedAudios/168 Hi this is Eric.json");
        break;
      case "169 In an international tone Jezz cuva":
        file = require("./encryptedAudios/169 In an international tone Jezz cuva.json");
        break;
      case "170 Phone dialing":
        file = require("./encryptedAudios/170 Phone dialing.json");
        break;
      case "171 Phone Dialing It Isnt Ringing":
        file = require("./encryptedAudios/171 Phone Dialing It Isnt Ringing.json");
        break;
      case "172 Thanks for calling":
        file = require("./encryptedAudios/172 Thanks for calling.json");
        break;
      case "173 What is the temperature by you":
        file = require("./encryptedAudios/173 What is the temperature by you.json");
        break;
      case "174 Have you been to Australia":
        file = require("./encryptedAudios/174 Have you been to Australia.json");
        break;
      case "175 Theyll never catch me again":
        file = require("./encryptedAudios/175 Theyll never catch me again.json");
        break;
      case "176 I told you I dont know where your dog is":
        file = require("./encryptedAudios/176 I told you I dont know where your dog is.json");
        break;
      case "177 Mr Daum is happy to speak to you His billing rate is 325 dollars per hour":
        file = require("./encryptedAudios/177 Mr Daum is happy to speak to you His billing rate is 325 dollars per hour.json");
        break;
      case "178 Oh I thought you were my ride":
        file = require("./encryptedAudios/178 Oh I thought you were my ride.json");
        break;
      case "179 What are you wearing":
        file = require("./encryptedAudios/179 What are you wearing.json");
        break;
      case "180 Wanna know what Im wearing":
        file = require("./encryptedAudios/180 Wanna know what Im wearing.json");
        break;
      case "181 Can you please call back":
        file = require("./encryptedAudios/181 Can you please call back.json");
        break;
      case "182 Nice to hear from you":
        file = require("./encryptedAudios/182 Nice to hear from you.json");
        break;
      case "183 My puppy has been doing the cutest things all day":
        file = require("./encryptedAudios/183 My puppy has been doing the cutest things all day.json");
        break;
      case "184 Hey great timing Can you settle":
        file = require("./encryptedAudios/184 Hey great timing Can you settle.json");
        break;
      case "185 I have been thinking about breaking into phone sales myself":
        file = require("./encryptedAudios/185 I have been thinking about breaking into phone sales myself.json");
        break;
      case "186 Its so nice to hear a young voice":
        file = require("./encryptedAudios/186 Its so nice to hear a young voice.json");
        break;
      case "187 I just got an online certification in astrology":
        file = require("./encryptedAudios/187 I just got an online certification in astrology.json");
        break;
      case "188 I just had a great session with my shrink":
        file = require("./encryptedAudios/188 I just had a great session with my shrink.json");
        break;
      case "189 Sure I will listen to your pitch":
        file = require("./encryptedAudios/189 Sure I will listen to your pitch.json");
        break;
      case "190 You are the hundredth caller today":
        file = require("./encryptedAudios/190 You are the hundredth caller today.json");
        break;
      case "191 Oh thank goodness you called":
        file = require("./encryptedAudios/191 Oh thank goodness you called.json");
        break;
      case "192 Im sorry who were you calling for":
        file = require("./encryptedAudios/192 Im sorry who were you calling for.json");
        break;
      case "193 Im unavailable to take your call right now":
        file = require("./encryptedAudios/193 Im unavailable to take your call right now.json");
        break;
      case "194 Honey Im on a deadline and will call you back at next break":
        file = require("./encryptedAudios/194 Honey Im on a deadline and will call you back at next break.json");
        break;
      case "195 Hi I completed that request about a year ago":
        file = require("./encryptedAudios/195 Hi I completed that request about a year ago.json");
        break;
      case "196 Im in California Are you complying with the California Consumer Privacy Act":
        file = require("./encryptedAudios/196 Im in California Are you complying with the California Consumer Privacy Act.json");
        break;
      case "197 Are you complying with the Telecommunications Privacy Act":
        file = require("./encryptedAudios/197 Are you complying with the Telecommunications Privacy Act.json");
        break;
      case "198 Telemarketer joke line":
        file = require("./encryptedAudios/198 Telemarketer joke line.json");
        break;
      case "199 Im sorry. I already have someone helping me with that":
        file = require("./encryptedAudios/199 Im sorry. I already have someone helping me with that.json");
        break;
      case "200 Mountain monastery Brother Frank speaking":
        file = require("./encryptedAudios/200 Mountain monastery Brother Frank speaking.json");
        break;
      case "201 St Delerious convent sister Mary Magdalene speaking":
        file = require("./encryptedAudios/201 St Delerious convent sister Mary Magdalene speaking.json");
        break;
      case "202 Green Acres Cemetery how may I help you":
        file = require("./encryptedAudios/202 Green Acres Cemetery how may I help you.json");
        break;
      case "203 Green Acres Cemetery Agnes speaking":
        file = require("./encryptedAudios/203 Green Acres Cemetery Agnes speaking.json");
        break;
      case "204 Teen Center Our office hours are 9am to 5pm":
        file = require("./encryptedAudios/204 Teen Center Our office hours are 9am to 5pm.json");
        break;
      case "205 Paging Sam. Sam you have a collection call on line 3":
        file = require("./encryptedAudios/205 Paging Sam. Sam you have a collection call on line 3.json");
        break;
      case "206 Sexually transmitted Diseases hotline":
        file = require("./encryptedAudios/206 Sexually transmitted Diseases hotline.json");
        break;
      case "207 You have reached the telemarketing call center":
        file = require("./encryptedAudios/207 You have reached the telemarketing call center.json");
        break;
      case "208 This number has been disconnected":
        file = require("./encryptedAudios/208 This number has been disconnected.json");
        break;
      case "209 You have reached a number that is disconnected":
        file = require("./encryptedAudios/209 You have reached a number that is disconnected.json");
        break;
      case "210 The number you dialed is not in service":
        file = require("./encryptedAudios/210 The number you dialed is not in service.json");
        break;
      case "211 Busy Signal U.S.":
        file = require("./encryptedAudios/211 Busy Signal U.S..json");
        break;
      case "212 Hold Music 7":
        file = require("./encryptedAudios/212 Hold Music 7.json");
        break;
      case "213 Busy Signal Europe":
        file = require("./encryptedAudios/213 Busy Signal Europe.json");
        break;
      case "test":
        file = require("./encryptedAudios/test.json");
        break;
    }

    return file;
  };

  const getLicenseKey = () => {
    try {
      const value = localStorage.getItem("licenseKey");
      if (value !== null) {
        // value previously stored

        return value;
      }
    } catch (e) {
      // error reading value
    }
  };

  const handleValidation = async () => {
    return new Promise(async (res) => {
      let licenseKey = getLicenseKey();
      const deviceId = localStorage.getItem("deviceId");
      const func = httpsCallable(functions, "validateLicense");

      func({
        licenseKey: licenseKey,
        deviceId: deviceId,
        web: true,
      }).then((response) => {
        res(response.data);
      });
    });
  };

  const handlePlayAudio = () => {
    handleValidation().then((res) => {
      if (res.success) {
        if (currentAudio.current) {
          currentAudio.current.pause();
        }
        const audioData = getAudioFile();
        const bytes = CryptoJS.AES.decrypt(
          audioData.value.toString(),
          process.env.REACT_APP_SECRET_KEY
        );
        const plaintext = bytes.toString(CryptoJS.enc.Utf8);
        const audio = new Audio("data:audio/mpeg;base64," + plaintext);
        currentAudio.current = audio;
        currentAudio.current.play();
      }
    });
  };

  const handleNextCategory = () => {
    handleValidation().then((res) => {
      if (res.success) {
        const newCount = selectedCategory + 1;
        if (allCategories[newCount]) {
          setSelectedCategory(newCount);
        }
      }
    });
  };

  const handleBackCategory = () => {
    handleValidation().then((res) => {
      if (res.success) {
        const newCount = selectedCategory - 1;
        if (allCategories[newCount]) {
          setSelectedCategory(newCount);
        }
      }
    });
  };

  const handleNextPhrase = () => {
    handleValidation().then((res) => {
      if (res.success) {
        const newCount = selectedSoundName + 1;
        if (phrasesInSelectedCategory[newCount]) {
          setSelectedSoundName(newCount);
        }
      }
    });
  };

  const handleBackPhrase = () => {
    handleValidation().then((res) => {
      if (res.success) {
        const newCount = selectedSoundName - 1;

        if (phrasesInSelectedCategory[newCount]) {
          setSelectedSoundName(newCount);
        }
      }
    });
  };

  const handleNextFavorite = () => {
    handleValidation().then((res) => {
      if (res.success) {
        const newCount = selectedFavorite + 1;
        if (favorites[newCount]) {
          setSelectedFavorite(newCount);
        }
      }
    });
  };

  const handleBackFavorite = () => {
    handleValidation().then((res) => {
      if (res.success) {
        const newCount = selectedFavorite - 1;

        if (favorites[newCount]) {
          setSelectedFavorite(newCount);
        }
      }
    });
  };

  const handleStop = () => {
    if (currentAudio.current) {
      currentAudio.current.pause();
    }
  };

  const handleVolumeDown = async () => {
    const newVolume = volume - 0.1;

    if (currentAudio.current && newVolume > 0) {
      setVolume(newVolume);

      currentAudio.current.volume = newVolume;
    }
  };

  const handleVolumeUp = async () => {
    const newVolume = volume + 0.1;
    if (currentAudio.current && newVolume < 1.1) {
      setVolume(newVolume);
      currentAudio.current.volume = newVolume;
    }
  };

  const handlePlayRandom = () => {
    handleValidation().then((res) => {
      if (res.success) {
        setSelectedSoundName(
          Math.floor(0 + Math.random() * (phrasesInSelectedCategory.length - 1))
        );
      }
    });
  };

  const handleAddToFavorites = (fav) => {
    handleValidation().then((res) => {
      if (res.success) {
        const storeData = async (value) => {
          try {
            const jsonValue = JSON.stringify(value);
            localStorage.setItem("favorites", jsonValue);
          } catch (e) {
            // saving error
          }
        };

        if (fav) {
          let currentFavorites = favorites;
          if (
            currentFavorites.findIndex((a) =>
              a.name ? a.name === fav.name : false
            ) === -1
          ) {
            currentFavorites.push(fav);
            setFavorites(currentFavorites);
            storeData(currentFavorites);
            setReload(!reload);
          }
        }
      }
    });
  };

  const handleRemoveFromFavorites = (fav) => {
    handleValidation().then((res) => {
      if (res.success) {
        const storeData = async (value) => {
          try {
            const jsonValue = JSON.stringify(value);
            localStorage.setItem("favorites", jsonValue);
          } catch (e) {
            // saving error
          }
        };

        if (fav) {
          let currentFavorites = favorites;
          let indexToRemove = currentFavorites.findIndex(
            (a) => a.name === fav.name
          );

          if (indexToRemove !== -1) {
            currentFavorites.splice(indexToRemove, 1);
            setFavorites(currentFavorites);
            storeData(currentFavorites);
            setSelectedFavorite(0);
            setReload(!reload);
          }
        }
      }
    });
  };

  return (
    <div
      style={{
        height: "720px",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        width: "480px",
        backgroundColor: "black",
      }}
    >
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          padding: 20,
          flexDirection: "row",
          display: "flex",
        }}
      >
        <div
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "30%",
            display: "flex",
          }}
        >
          <div style={{ width: "80%" }}>
            <div
              style={{ textAlign: "center", marginTop: 10, color: "yellow" }}
            >
              Copyright 2022 Atlara Holdings LLC
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", width: "56%" }}>
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              marginBottom: 12,
            }}
          >
            <div
              style={{
                textAlign: "center",
                color: "yellow",
                fontSize: 26,
                marginLeft: 76,
              }}
            >
              FightBack!
            </div>
            <div
              onClick={() =>
                window.open(
                  "http://webhelprx.com/FightBack!Help.html",
                  "_blank"
                )
              }
              style={{ marginLeft: "98px", cursor: "pointer", zIndex: 1 }}
            >
              <img style={{ width: 50 }} src={helpImage} />
            </div>
          </div>
          <img
            style={{
              border: "1px solid white",
              height: 200,
              width: "100%",
            }}
            src={speakerImage}
          />
        </div>
        <div
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "26%",
            display: "flex",
          }}
        >
          <div
            onClick={handleVolumeUp}
            style={{
              width: 40,
              height: 40,
              alignItems: "center",
              justifyContent: "center",
              margin: 10,
              backgroundColor: "white",
              display: "flex",
              cursor: "pointer",
            }}
          >
            <FaPlus />
          </div>
          <div>
            <div style={{ color: "white" }}>Vol</div>
          </div>
          <div
            onClick={handleVolumeDown}
            style={{
              width: 40,
              height: 40,
              alignItems: "center",
              justifyContent: "center",
              margin: 10,
              backgroundColor: "white",
              display: "flex",
              cursor: "pointer",
            }}
          >
            <FaMinus />
          </div>
        </div>
      </div>
      <div
        style={{
          width: "92%",
          justifyContent: "center",
          padding: 20,
          flexDirection: "row",
          alignSelf: "center",
          height: 120,
          alignItems: "center",
          backgroundColor: "#00E6FF",
          display: "flex",
        }}
      >
        <div
          style={{
            width: "40%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            textAlign: "center",
          }}
        >
          <div style={{ color: "black", fontWeight: "800", fontSize: 28 }}>
            {favoriteMode ? "Favorites" : allCategories[selectedCategory]}
          </div>
        </div>
        <div
          style={{
            width: "60%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <div
            style={{
              color: "black",
              fontWeight: "800",
              fontSize: 21,
              marginLeft: 20,
              textAlign: "center",
            }}
          >
            {favoriteMode
              ? favorites[selectedFavorite]
                ? favorites[selectedFavorite].name
                : "No Favorites Saved"
              : phrasesInSelectedCategory[selectedSoundName]
              ? phrasesInSelectedCategory[selectedSoundName].name
              : ""}
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          padding: 20,
          flexDirection: "row",
          alignSelf: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <div
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <div
            onClick={favoriteMode ? handleNextFavorite : handleNextPhrase}
            style={{
              width: 60,
              height: 60,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#00D700",
              display: "flex",
              margin: 5,
              cursor: "pointer",
            }}
          >
            <FaChevronUp size={26} />
          </div>

          <div
            onClick={favoriteMode ? handleBackFavorite : handleBackPhrase}
            style={{
              width: 60,
              height: 60,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#00D700",
              display: "flex",
              margin: 5,
              cursor: "pointer",
            }}
          >
            <FaChevronDown size={26} />
          </div>
          <div style={{ marginTop: 10 }}>
            <div style={{ color: "white" }}>Phrase</div>
          </div>
        </div>
        <div
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "4%",
            display: "flex",
          }}
        >
          <div
            onClick={() =>
              handleAddToFavorites(phrasesInSelectedCategory[selectedSoundName])
            }
            style={{
              width: 30,
              height: 30,
              alignItems: "center",
              justifyContent: "center",
              margin: 6,
              backgroundColor: "white",
              display: "flex",
              cursor: "pointer",
            }}
          >
            <FaPlus />
          </div>
          <div
            onClick={() => setFavoriteMode(!favoriteMode)}
            style={{ paddingTop: 8, paddingBottom: 8, cursor: "pointer" }}
          >
            <div style={{ color: "white" }}>Fav</div>
          </div>
          <div
            onClick={() =>
              handleRemoveFromFavorites(
                favoriteMode
                  ? favorites[selectedFavorite]
                  : phrasesInSelectedCategory[selectedSoundName]
              )
            }
            style={{
              width: 30,
              height: 30,
              alignItems: "center",
              justifyContent: "center",
              margin: 6,
              backgroundColor: "white",
              display: "flex",
              cursor: "pointer",
            }}
          >
            <FaMinus />
          </div>
        </div>

        <div style={{ flexDirection: "column", marginLeft: "4%" }}>
          <div
            onClick={handlePlayAudio}
            style={{
              width: 80,
              height: 80,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#FF9800",
              border: "4px solid white",
              display: "flex",
              cursor: "pointer",
            }}
          >
            <FaPlay />
          </div>

          <div
            onClick={handlePlayRandom}
            style={{
              width: 88,
              height: 30,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
              marginTop: 20,
              display: "flex",
              cursor: "pointer",
            }}
          >
            <div>Random</div>
          </div>
        </div>

        <div
          onClick={handleStop}
          style={{
            width: 80,
            height: 80,
            border: "4px solid white",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "6%",
            backgroundColor: "#B21F04",
            display: "flex",
            cursor: "pointer",
          }}
        >
          <FaSquare />
        </div>
      </div>
      <div
        style={{
          width: "100%",
          padding: 4,
          flexDirection: "row",
          alignSelf: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <div
          style={{
            alignItems: "center",
            marginLeft: "6%",
            marginTop: -30,
            marginBottom: 10,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ flexDirection: "row", display: "flex" }}>
            <div
              onClick={handleBackCategory}
              style={{
                backgroundColor: "#FFF000",
                width: 60,
                height: 60,
                justifyContent: "center",
                alignItems: "center",
                margin: 6,
                display: "flex",
                cursor: "pointer",
              }}
            >
              <FaChevronLeft size={26} />
            </div>
            <div
              onClick={handleNextCategory}
              style={{
                backgroundColor: "#FFF000",
                width: 60,
                height: 60,
                justifyContent: "center",
                alignItems: "center",
                margin: 6,
                cursor: "pointer",
                display: "flex",
              }}
            >
              <FaChevronRight size={26} />
            </div>
          </div>
          <div
            style={{
              color: "white",
              fontSize: 16,
              fontWeight: "700",
            }}
          >
            Category
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
